<template>
  <div class="masonry-tile">
    <ZoomContainer
      ref="zoomContainer"
      :enabled="enableZoomContainer"
      :class="{loading: isLoading}">
      <LazyImage
          :image-source="tileImage.url"
          :alt-text="tileImage.title"
          :container="pageContainer"
          :load="load"
          :ready-to-show="rowReady"
          @loaded="$emit('loaded')"
          @imageShowing="removeLoadingClass"
          @click="showGallery(tile.id)"/>
    </ZoomContainer>
  </div>
</template>

<script>
import LazyImage from '@/components/ui/LazyImage'
import ZoomContainer from '@/components/ui/ZoomContainer'
import {mapActions} from 'vuex'

export default {
  name: 'MasonryTile',
  components: {LazyImage, ZoomContainer},
  props: {
    tile: {
      type: Object,
      required: false,
      default: null
    },
    load: {
      type: Boolean,
      required: false,
      default: false
    },
    rowReady: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      enableZoomContainer: false,
      isLoading: true,
    }
  },
  computed: {
    tileImage(){
      return this.tile.tileImage[0]
    },
    pageContainer() {
      return document.querySelector('#app > .page')
    }
  },
  methods: {
    ...mapActions('gallery', {
      toggleGalleryShow: 'toggleGalleryShow',
      activateGalleryId: 'activateGalleryId'
    }),
    removeLoadingClass() {
      setTimeout(() => {
        this.enableZoomContainer = true
        this.isLoading = false
      }, 700)
    },
    showGallery(tileId) {
      this.toggleGalleryShow()
      this.activateGalleryId(tileId)
    }
  }
}
</script>

<style lang="scss" scoped>
.masonry-tile {
}
</style>