<template>
  <div
      v-if="masonryRows.length"
      ref="masonryContainer"
      class="masonry-container">
    <MasonryRow
      v-for="(masonryRow, index) in masonryRows"
      :key="index"
      ref="masonryRowElements"
      :dominant="masonryRow.dominant"
      :tiles="masonryRow.tile"
      :ready-to-load="readyToLoad"
      @loaded="rowsLoaded[index] = true;firstRowLoaded = true"/>
  </div>
</template>

<script>
import MasonryRow from '@/components/masonry/MasonryRow'

export default {
  name: 'MasonryContainer',
  components: {MasonryRow},
  props: {
    masonryRows: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      initialCheckComplete: false,
      readyToLoad: [],
      rowsLoaded: [],
      firstRowLoaded: false
    }
  },
  computed: {
    appPage() {
      return document.querySelector('#app > .page')
    }
  },
  watch: {
    masonryRows: function() {
      this.initContainer()
    },
    firstRowLoaded(newVal, oldVal) {
      if (newVal && oldVal !== newVal) {
        this.$emit('loaded')
      }
    }
  },
  mounted() {
    this.initContainer()
  },
  methods: {
    initContainer() {
      if (! this.masonryRows.length) return
      this.setReadyToLoad().then(() => {
        this.setListeners()
      })
    },
    setReadyToLoad() {
      // Initially setting all to false.
      return new Promise(resolve => {
        this.masonryRows.forEach((row, index) => {
          this.readyToLoad.push(index === 0)
        })

        resolve()
      })
    },
    setListeners () {
      window.addEventListener('resize', this.checkReadyToLoad)
      this.appPage.addEventListener('scroll', this.checkReadyToLoad)

      setTimeout(() => {
        // Initial call
        this.checkReadyToLoad()
      }, 100)
    },
    checkReadyToLoad() {
      const bottomOfWindow = window.innerHeight
      // How far from the top is the .page container scrolled.
      const scrollTop = this.appPage.scrollTop
      // How far the bottom of the window is scrolled in.
      const bottomOfWindowAtScroll = bottomOfWindow + scrollTop

      const masonryRowElements = this.$refs.masonryRowElements

      let cloneReadyToLoad = [...this.readyToLoad]
      masonryRowElements.forEach((row, index) => {
        if (cloneReadyToLoad[ index ]) {
          // Already scrolled to a point where the items have been loaded. Do nothing.
          return
        }

        if (row.$el.offsetTop <= (bottomOfWindowAtScroll + 600) && this.rowsLoaded[ index - 1 ]) {
          // Row is within the loading range, and the elder sibling is loaded.
          cloneReadyToLoad[ index ] = true
          this.removeListenerCheck(cloneReadyToLoad)
          return
        }

        cloneReadyToLoad[ index ] = false
      })

      if (JSON.stringify(this.readyToLoad) !== JSON.stringify(cloneReadyToLoad) || !this.initialCheckComplete) {
        // Using a clone instead of mutating the data object allows child watchers to work.
        this.readyToLoad = cloneReadyToLoad
        this.initialCheckComplete = true
      }
    },
    removeListenerCheck(arrayList = []) {
      if (arrayList.every((item) => item === true)) {
        window.removeEventListener('resize', this.checkReadyToLoad)
        this.appPage.removeEventListener('scroll', this.checkReadyToLoad)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.masonry-container {}
</style>