<template>
  <div class="portfolio-page">
    <PageTitle v-if="showTitle" :title="title" />
    <MasonryContainer
        :masonry-rows="masonryRows"
        @loaded="toggleShowPage"/>
    <GalleryContainer :gallery-assets="galleryAssets"/>
    <Footer/>
  </div>

</template>

<script>
import {mapActions} from 'vuex'
import api from '@/api'
import PageTitle from '@/components/ui/PageTitle';
import MasonryContainer from '@/components/masonry/MasonryContainer';
import GalleryContainer from '@/components/gallery/GalleryContainer';
import Footer from '@/components/footer/Footer'

export default {
  name: 'Portfolio',
  components: {PageTitle, MasonryContainer, GalleryContainer, Footer},
  data () {
    return {
      title: '',
      showTitle: false,
      masonryRows: [],
    }
  },
  computed: {
    galleryAssets() {
      return this.masonryRows.map(row => row.tile).flat()
        .map(tile => {
          return this.tileToGalleryImage(tile)
        })
    }
  },
  mounted() {
    this.loadPortfolio()
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    }),
    loadPortfolio(){
      const slug = this.$route.params.slug || 'home'

      // Load from local storage first.
      let entry = JSON.parse(localStorage.getItem(`portfolio${slug}`))
      if (entry) {
        this.updatePageTitle(entry.seo.title)
        this.applyEntryValues(entry)
      }


      api.Portfolio.loadPortfolio(slug).then(response => response.data)
        .then(data => {
          if (data.errors !== undefined) {
            alert('Failed to load portfolio entry. Reason: '.data.errors[0])
            return
          }

          entry = data.data.entry

          localStorage.setItem(`portfolio${slug}`, JSON.stringify(entry))

          this.updatePageTitle(entry.seo.title)
          this.applyEntryValues(entry)
        }).catch(() => {
          this.updatePageTitle(entry.seo.title)
          this.applyEntryValues(entry)
        })
    },
    applyEntryValues(entry) {
      this.title = entry.title
      this.showTitle = entry.showTitle
      this.masonryRows = entry.masonryContainer
    },
    tileToGalleryImage(tile) {
      return {
        id: tile.id,
        placeholder: {
          src: tile.tileImage[0].url,
          title: tile.tileImage[0].title
        },
        galleryImage: {
          src: tile.galleryImage[0].url,
          title: tile.galleryImage[0].title
        }
      }
    },
  }
}
</script>
