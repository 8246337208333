<template>
  <div
    class="masonry-row"
    :class="{ 'dominant-right': dominant, 'dominant-left': !dominant }">
    <template
        v-for="(tile, index) in organizedTiles">
      <div
        v-if="Array.isArray(tile)"
        :key="index"
        class="hiccup-container width-67">
        <MasonryTile
          v-for="(hiccupTile, hiccupIndex) in tile"
          class="hiccup width-50"
          ref="masonryTiles"
          :key="hiccupIndex"
          :tile="hiccupTile"
          :load="loadAssets"
          :row-ready="rowReady"
          @loaded="tilesLoaded += 1"/>
      </div>
      <MasonryTile
        v-else
        ref="masonryTiles"
        :key="index"
        :tile="tile"
        :load="loadAssets"
        :row-ready="rowReady"
        @loaded="tilesLoaded += 1"/>
    </template>
  </div>
</template>

<script>
import MasonryTile from '@/components/masonry/MasonryTile'

export default {
  name: 'MasonryRow',
  components: {MasonryTile},
  props: {
    dominant: {
      type: Boolean,
      required: false,
      default: false,
    },
    tiles: {
      type: Array,
      required: false,
      default: () => [],
    },
    readyToLoad: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      heightToPass: 0.0,
      loadAssets: false,
      tilesLoaded: 0,
      inHiccup: false,
      rowReady: false
    }
  },
  computed: {
    organizedTiles () {
      if (! this.tiles.length) return this.tiles

      let organized = []
      let hiccupCount = 0

      this.tiles.map((tile) => {
        if (! tile.hiccup) {
          organized.push(tile)
          hiccupCount = 0
          return
        }

        const lastItemIsArray = Array.isArray(organized[organized.length - 1])

        // Hiccup tile. check to see if the last item is array (hiccup)
        if (hiccupCount >= 2 || ! lastItemIsArray) {
          organized.push([tile])
          hiccupCount = 1
          return
        }

        // The last item is an array already, so just push this to the last.
        organized[organized.length - 1].push(tile)
        hiccupCount += 1
      })

      return organized
    }
  },
  watch: {
    readyToLoad: function (newVal) {
      // The :key value assigned to the MasonryRow component, in the parent.
      const key = this.$vnode.key
      this.loadAssets = newVal[key] === true
    },
    tilesLoaded: function (newVal) {
      if (newVal >= this.tiles.length) {
        // setTimeout(() => {
          this.configureLayout()
              .then(() => {
                this.rowReady = true
                this.$emit('loaded')
              })
        // }, 200)
      }
    }
  },
  mounted() {
    //
  },
  methods: {
    configureLayout(){
      return new Promise((resolve) => {
        const tiles = this.$refs.masonryTiles
        for (let i = 1; i <= tiles.length; i++) {
          const thisTile = tiles[i - 1].$el
          if (this.dominant) {
            this.dominantRight(i, thisTile)
          } else {
            this.dominantLeft(i, thisTile)
          }
        }

        resolve();
      })
    },
    dominantRight(index, tile){
      if (tile.classList.contains('hiccup') && !this.inHiccup) {
        // Only grab the parent node height for one of the hiccup containers.
        this.addHeightToPass(tile.parentNode)
        this.inHiccup = true
        return
      } else if (tile.classList.contains('hiccup') && this.inHiccup) {
        return;
      }

      this.inHiccup = false

      if (index === 1) {
        tile.classList.add('width-33')
        this.subHeightToPass(tile);
        return
      } else if (index === 2) {
        tile.classList.add('width-67')
        this.addHeightToPass(tile);
        return
      }

      this.widthBasedOnHeightToPass(tile)
    },
    dominantLeft(index, tile){
      if (tile.classList.contains('hiccup') && !this.inHiccup) {
        this.addHeightToPass(tile.parentNode)
        this.inHiccup = true
        return
      } else if (tile.classList.contains('hiccup') && this.inHiccup) {
        return;
      }

      this.inHiccup = false

      if (index === 1) {
        tile.classList.add('width-67')
        this.addHeightToPass(tile);
        return
      } else if (index === 2) {
        tile.classList.add('width-33')
        this.subHeightToPass(tile);
        return
      }

      this.widthBasedOnHeightToPass(tile)
    },
    addHeightToPass(tile){
      if (process.env.NODE_ENV === "development") tile.dataset.heightToPassBefore = this.heightToPass
      this.heightToPass += tile.getBoundingClientRect().height
      if (process.env.NODE_ENV === "development") tile.dataset.heightToPassAfter = this.heightToPass
    },
    subHeightToPass(tile){
      if (process.env.NODE_ENV === "development") tile.dataset.heightToPassBefore = this.heightToPass
      this.heightToPass -= tile.getBoundingClientRect().height
      if (process.env.NODE_ENV === "development") tile.dataset.heightToPassAfter = this.heightToPass
    },
    widthBasedOnHeightToPass(tile){
      if (this.heightToPass <= 0) {
        tile.classList.add('width-67')
        this.addHeightToPass(tile);
      } else {
        tile.classList.add('width-33')
        this.subHeightToPass(tile);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.masonry-row {
  &:after {
    @apply invisible;
    @apply block;
    @apply h-0;
    content: " ";
    clear: both;
  }

  &.dominant-left {
    .width-67 {
      @apply float-left;
      padding-right: 60px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 767px) {
        @apply pr-0;
      }
    }

    .width-33 {
      @apply float-right;
    }
  }

  &.dominant-right {
    .width-67 {
      @apply float-right;
      padding-left: 60px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 767px) {
        @apply pl-0;
      }
    }

    .width-33 {
      @apply float-left;
    }
  }

  .width-33, .width-50, .width-67 {
    @apply inline-block;
    padding-bottom: 134px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding-bottom: 68px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 24px;
    }
  }

  .width-67 {
    @apply w-4/6;

    @media screen and (max-width: 767px) {
      @apply w-full;
    }
  }

  .width-33 {
    @apply w-2/6;

    @media screen and (max-width: 767px) {
      @apply w-full;
    }
  }

  .hiccup-container {
    @apply flex;

    .width-50 {
      @apply w-1/2;
      @apply pb-0;

      &:nth-of-type(odd) {
        padding-right: 30px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          padding-right: 15px;
        }
        @media screen and (max-width: 767px) {
          @apply pr-0;
        }
      }

      &:nth-of-type(even) {
        padding-left: 30px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          padding-left: 15px;
        }
        @media screen and (max-width: 767px) {
          @apply pl-0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      @apply flex-col;
      .width-50 {
        @apply w-full;

        &:first-of-type {
          padding-bottom: 24px;
        }
      }
    }
  }
}

</style>